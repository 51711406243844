import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { containerMaxWidth } from "../constants"
import Layout from "../components/Layout"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 14.2rem auto;
  padding: 0 3rem;
`

const Title = styled.h1`
  padding: 6rem 3rem 1rem;
  text-align: center;
`

const Text = styled.p`
  padding: 0;
  text-align: center;
`

const Error404 = ({ pageContext }) => {
  const { t } = useTranslation("translations")

  return (
    <Layout
      meta={{ title: t("Looks like the page you requested does not exist.") }}
      invertedHeader
      pageContext={pageContext}
    >
      <Container>
        <Title>{t("Oops!")}</Title>
        <Text>{t("Looks like the page you requested does not exist.")}</Text>
      </Container>
    </Layout>
  )
}

export default Error404
